<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Previous Due
            <v-spacer></v-spacer>
            <add-button
              v-if="
                createJournalStats &&
                createJournalStats.total_records >
                  createJournalStats.journaled &&
                currentYear
              "
              class="mr-3"
              :permission="'journal-create'"
              @action="fetchCreateJournalStats(true)"
            >
              Create Journal
            </add-button>

            <v-download-column-select
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator' ||
                  $auth.loggedInRole().slug === 'accountant') &&
                form.items.data.length > 0
              "
              :optionType="'radio'"
              :showPDF="false"
              :columnHeader="'DOWNLOAD TYPES'"
              :downloadExcelText="'Begin Download/Upload'"
              :downloadColumns="downloadColumns"
              @onDownload="downloadResult"
            >
              DOWNLOAD/UPLOAD OPTION
            </v-download-column-select>

            <!-- <print-buttons
              label="Download Due Report"
              :passAuthToReport="true"
              v-if="downloadUrl"
              :download-url="downloadUrl"
              :pdf="false"
            ></print-buttons> -->
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm3>
                <v-select
                  :disabled="gradeLoading"
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :loading="sectionLoading"
                  :disabled="!grade || !sections.length"
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :items="statuses"
                  class="pa-0"
                  label="Status"
                  v-model="status"
                  outlined
                  dense
                />
              </v-flex>

              <v-flex xs3 sm3>
                <search-button
                  :disabled="!grade || !status"
                  :permission="true"
                  @action="get()"
                >
                  Search
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>

          <div
            v-if="createJournalStats"
            class="data-represent"
            style="margin-top: 30px"
          >
            <div>
              <span class="d-success"></span> &nbsp;
              <strong> {{ createJournalStats.invoice_included }}</strong>
              <small> Invoice Included</small>
            </div>
            <div>
              <span class="d-warning"></span> &nbsp;
              <small>{{ createJournalStats.journaled }}</small>
              <strong>
                /
                {{
                  createJournalStats.total_records -
                  createJournalStats.journaled
                }}</strong>
              <small> (Journaled / Pending) Records</small>
            </div>
            <div>
              <span class="d-primary"></span>
              <strong> {{ createJournalStats.total_records }}</strong>
              <small> Total Due Records</small>
            </div>

            <div>
              <span class="d-error"></span>
              <small> Total Due Amount:</small> &nbsp;
              <small
                ><strong>{{
                  createJournalStats.total_amount.currency()
                }}</strong></small
              >
            </div>
            <div v-if="form.items.data.length > 0">
              <span class="d-info"></span>
              <small> Filtered Amount:</small> &nbsp;
              <small
                ><strong>{{ filterAmount.currency() }}</strong></small
              >
            </div>
          </div>
          <br />
          <v-data-table
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            :headers="headers"
            :items="form.items.data"
            hide-default-footer
            sort-by="id"
            :loading="form.loading"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-xs-left">{{ ++index }}</td>
                <td class="text-xs-left">{{ item.roll }}</td>
                <td class="text-xs-left">
                  {{ item.enroll_code }}
                </td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">
                  {{ item.grade }} / {{ item.section }}
                </td>
                <td>-</td>
                <td class="text-right">
                  <span>
                    <input
                      @wheel="$event.target.blur()"
                      @keyup="updateReady = true"
                      @focus="$event.target.select()"
                      @keyup.enter="savePreviousDue(item)"
                      type="number"
                      class="inputType"
                      v-model="item.amount"
                      :disabled="
                        parseInt(item.stat) === 3 ||
                        parseInt(item.stat) === 2 ||
                        !createJournalStats.allow_posting
                      "
                    />
                  </span>
                </td>
                <td class="text-right" v-if="$auth.isAdmin()">
                  <delete-button
                    :disabled="(item.previous_due_id === null ||  parseInt(item.stat) === 1 || parseInt(item.amount) === 0 ||   parseInt(item.stat) === 3 ||
                        parseInt(item.stat) === 2 || !createJournalStats.allow_posting) ? true: false"
                    :permission="true"
                    ref="deleteButtonRef"
                    :showLoader="loading"
                    :dialogueClose="true"
                    @agree="deletePreviousDue(item.previous_due_id)"
                    :key="compKey"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
          <br />

          <v-btn
            :loading="saving"
            @click="savePreviousDue"
            v-if="form.items.data.length > 0 && enableSaveButton"
            block
            :disabled="!updateReady || saving"
            color="primary"
            large
            >Save Previous Dues</v-btn
          >
        </v-card>
        <input
          @change="uploadExcelFile"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          type="file"
          ref="upload_due_input"
        />
      </v-flex>
      <v-dialog v-model="createJournalModal" persistent max-width="800">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Create Journal</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <alert-message
              extraSmall
              type="warning"
              title="Create Journal Alert"
              message="By creating journal all your previous due records will be synced to accounting system. No further edit/create is allowed after the sync process is complete"
            ></alert-message>

            <v-flex xs12>
              <table
                class="basic_styled_table"
                width="100%"
                v-if="createJournalStats"
                border="1"
                cellpadding="5"
                cellspacing="5"
              >
                <thead>
                  <tr>
                    <th>Total Due Amount</th>
                    <th>Invoiced</th>
                    <th>Journal Complete / Pending</th>
                    <th>Total Records</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>{{
                        createJournalStats.total_amount.currency()
                      }}</strong>
                    </td>
                    <td>
                      <strong>{{ createJournalStats.invoice_included }}</strong>
                    </td>
                    <td>
                      <strong
                        >{{ createJournalStats.journaled }}
                        /
                        {{
                          createJournalStats.total_records -
                          createJournalStats.journaled
                        }}</strong
                      >
                    </td>

                    <td>
                      <strong>{{ createJournalStats.total_records }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-textarea
                v-model="journal_remarks"
                outlined
                label="Narration"
                rows="3"
              ></v-textarea>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createJournalModal = false"
              >Close and Verify Again</v-btn
            >
            <v-btn
              :disabled="journal_remarks.length < 6"
              color="warning"
              text
              @click="createToJournal(createJournalStats.total_amount)"
              >I have verified all the records</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <!-- <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import Mixins from '@/library/Mixins';
import { redirectDownloadUrl } from '../../../../library/helpers';

export default {
  mixins: [Mixins],
  data: () => ({
    gradeLoading: false,
    createJournalStats: null,
    createJournalModal: false,
    journal_remarks: '',
    saving: false,
    currentYear: false,
    downloadColumns: [
      {
        value: 'download_all_report',
        name: 'Download All Previous Due Report',
        selected: false,
      },
      {
        value: 'download_report',
        name: 'Download Previous Due Report',
        selected: false,
      },
      {
        value: 'download_excel_raw',
        name: 'Download Excel File (RAW)',
        selected: false,
      },
      { value: 'upload_excel', name: 'Upload Excel', selected: false },
    ],
    updateReady: false,
    form: new Form(
      {
        amount: '',
        enroll_code: '',
        stat: 0,
      },
      '/api/previous-due'
    ),
    pagination: {
      rowsPerPage: 120,
      itemsPerPage: 200,
    },
    sectionLoading: false,
    headers: [
      { text: 'S.No.', align: 'left', value: 'seriel_no', sortable: false },
      { text: 'Roll', align: 'left', value: 'id', sortable: false },
      {
        text: 'Enroll Code',
        align: 'left',
        value: 'enroll_code',
        sortable: false,
      },
      { text: 'Name', align: 'left', value: 'name', sortable: false },
      {
        text: 'Grade / Section',
        align: 'left',
        value: 'grade',
        sortable: false,
      },
      {
        text: 'Remarks',
        align: 'left',
        value: 'remarks',
        sortable: false,
      },
      { text: 'Amount', align: 'right', value: 'amount', sortable: false },
      // { text: "Action", align: "right", value: "action", sortable: false },

    ],
    grades: [],
    grade: '',
    enrollCode: '',
    sections: [],
    section: '',
    accountServiceStatus: false,
    downloadUrl: '',
    statuses: [
      { value: 'all', text: 'All' },
      { value: '2', text: 'Journal Complete' },
      { value: '1', text: 'Journal Pending' },
    ],
    status: 'all',
    filterAmount: 0,
    loading: false,
    compKey:0,
  }),

  computed: {
    ...mapState(['batch']),
    enableSaveButton() {
      let totalJournaledCount = 0;
      this.form.items.data.map((item) => {
        if (item.stat === '2') {
          totalJournaledCount++;
        }
      });
      return totalJournaledCount != this.form.items.data.length;
    },
  },

  mounted() {
    this.getGrades();
    this.fetchCreateJournalStats();
    if (this.$auth.getServices('ACCOUNT')) this.accountServiceStatus = true;
    else this.accountServiceStatus = false;
    this.getFiscalYear();
    
    if(this.$auth.isAdmin()){
      let actionAppend  = [
        { text: "Action", align: "right", value: "action", sortable: false },
      ]
      this.headers = [...this.headers,...actionAppend]
    }
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function () {},

    grade: function () {
      this.section = '';
      this.sections = [];
      this.getSection();
      this.form.items.data = [];
    },
    section: function () {
      this.month = '';
      this.generate = false;
      this.print = false;
      this.form.items.data = [];
    },
  },

  methods: {
    downloadResult({ type, columns, columnString }) {
      switch (columnString) {
        case 'download_all_report':
          window.open(this.downloadUrl + 'excel' + '&all=true', '_blank');
          break;
        case 'download_report':
          window.open(this.downloadUrl + 'excel', '_blank');
          break;
        case 'download_excel_raw':
          redirectDownloadUrl({
            uri: '/download/student/list',
            queryString: `gradeId=${this.grade}&flag=all&type=excel&column=enroll_code,enroll_id,name,grade,section,previous_due`,
          });
          break;
        case 'upload_excel':
          this.$refs.upload_due_input.click();
          break;
      }
    },

    uploadExcelFile(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('previous_due_sheet', file);
      this.$rest
        .post(`/api/previous-due/upload`, formData)
        .then(() => {
          this.$events.fire('notification', {
            message: 'Uploaded successfully',
            status: 'success',
          });
          this.get();
          this.fetchCreateJournalStats();
          this.updateReady = false;
        })
        .catch((err) => {
          this.$events.fire('notification', {
            message: err.response.data.message,
            status: 'error',
          });
        })
        .finally(() => {
          this.$refs.image.value = null;
        });
    },

    fetchCreateJournalStats(openModal = false) {
      this.$rest.get('/api/previous-due/stats').then(({ data }) => {
        this.createJournalStats = data;
        if (data.total_amount > 0 && data.journaled < data.total_records) {
          this.createJournalModal = openModal;
        }
      });
    },

    get() {
      if (this.grade && this.status) {
        let extraParams =
          'gradeId=' +
          this.grade +
          '&sectionId=' +
          this.section +
          '&enrollCode=' +
          this.enrollCode.trim() +
          '&search=' +
          this.status;

        this.form.get(null, this.queryString(extraParams)).then(({ data }) => {
          this.filterAmount = 0;
          data.data.map((res) => {
            this.filterAmount += res.amount;
          });
          this.pagination.totalItems = data.meta.total;
          this.downloadUrl = data.download_url;
        });
      }
    },

    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get('/api/grades?rowsPerPage=25&descending=false&slim=true')
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },

    getSection() {
      if (this.grade) {
        this.sectionLoading = true;
        this.$rest
          .get('/api/section?grade=' + this.grade)
          .then(({ data }) => {
            this.sections = data.data.map((item) => {
              return { value: item.id, text: item.name };
            });
          })
          .finally(() => {
            this.sectionLoading = false;
          });
      }
    },

    createToJournal(totalAmount) {
      this.$rest
        .post('/api/journal-entry/previous-due', {
          amount: totalAmount,
          account_service_status: this.accountServiceStatus,
          narration: this.journal_remarks,
        })
        .then(({ data }) => {
          this.$events.fire('notification', {
            message: data.message,
            status: 'success',
          });
          this.get();
          this.fetchCreateJournalStats();

          this.createJournalModal = false;
          this.journal_remarks = '';
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'error',
          });
        })
        .finally(() => {});
    },

    savePreviousDue(item) {
      this.saving = true;
      this.$rest
        .post('/api/previous-due', {
          previous_dues: item && item.amount ? [item] : this.form.items.data,
        })
        .then((res) => {
          this.$events.fire('notification', {
            message: res.data.message,
            status: 'success',
          });
          this.get();
          this.fetchCreateJournalStats();
          this.updateReady = false;
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'error',
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getFiscalYear() {
      let years = JSON.parse(window.localStorage.getItem('fiscalyear'));
      this.currentYear = years.is_current;
    },
    deletePreviousDue(id){
      this.loading = true
      this.$rest.delete('/api/previous-due/'+id)
      .then((res)=>{
        this.$events.fire('notification', {
            message: res.data.message,
            status: 'success',
          });
        this.get()
      })
      .catch((err)=>{
        this.$events.fire('notification', {
            message: err.response.data.message,
            status: 'error',
          });
      }).finally(()=>{
        this.loading = false;
        this.$refs.deleteButtonRef.reset();
        this.compKey += 1 
      })
    }
  },
};
</script>
<style lang="scss">
.verified {
  background: #4caf50 !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.total {
  background: red !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
}

.unverified {
  background: #fb8c00 !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
}

.inputType {
  border: 1px solid #bbb;
  text-align: center;
  height: 34px;
  width: 86px;
  &:focus {
    outline: none;
  }
  &[disabled] {
    color: #999;
  }
}

.inputType:disabled {
  cursor: not-allowed;
  background: #eee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
